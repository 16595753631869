<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!--复盘目的  -->
              <div class="range" id="fpxz">
                <h4 class="title">复盘须知</h4>
                <el-divider></el-divider>
                <p class="describe">
                  为了规范每日工作复盘管理，提高员工工作效率，增强企业竞争力，特制定本意见
                </p>
              </div>
              <!-- 适用范围 -->
              <div class="range" id="syfw">
                <h4 class="title">适用范围</h4>
                <el-divider></el-divider>
                <p class="describe">
                  公司全体员工每个工作日，需将当日工作内容在小程序中简要归纳总结，于当日
                  17:00—21： 00 之前上传，以供公司领导审查。
                </p>
              </div>

              <!-- 复盘规范 -->
              <div class="range" id="fpgf">
                <h4 class="title">复盘规范</h4>
                <el-divider></el-divider>
                <ul>
                  <li>
                    <p class="describe">
                      1.若当日为工作日，因请假调休未能来园区上班，仍需复盘。复盘内容为：今日调休或
                      今日请假。
                    </p>
                  </li>

                  <li>
                    <p class="describe">2. 若当日为正常休息日，可以不复盘。</p>
                  </li>
                  <li>
                    <p class="describe">3. 若休息日加班，则当日也需要复盘。</p>
                  </li>

                  <li>
                    <p class="describe">
                      4. 每日晚上 21:00
                      前，必须完成复盘；由公司指定人员（智慧湾：曹芳燕，其余园区：
                      沈一鸣，仲苏豫）于 21：00-21：30
                      对各园区各部门进行检查当日是否完成复盘，若发现
                      员工未复盘及时督促，若多次未按时复盘，上报给办公室部门进行登记。
                    </p>
                  </li>

                  <li>
                    <p class="describe">
                      5.
                      部门主管需每周检查自己部门人员的复盘，以及复盘事项落实情况，杜绝虚假复盘，
                      未使用正确模板复盘，复盘数据不准确的情况。
                    </p>
                  </li>
                  <li>
                    <p class="describe">
                      6.
                      呈报的工作内容须真实、全面，汇报事项必须明确、完整、规范，员工及时呈报发现
                      的问题并提出合理建议。
                    </p>
                  </li>
                </ul>
              </div>
              <!-- 注意事项 -->
              <div class="range" id="zysx">
                <h4 class="title">注意事项</h4>
                <el-divider></el-divider>
                <h5 class="f-title">招商</h5>
                <ul>
                  <li>
                    <p class="describe">
                      1. 合同签约必须使用合同签约模板，由部门主管监督检查。
                    </p>
                  </li>
                  <li>
                    <p class="describe">
                      2. 访客接待必须使用访客接待模板，由部门主管监督检查。
                    </p>
                  </li>
                  <li>
                    <p class="describe">
                      3.
                      每周一各园区负责人在复盘内汇报上周空置面积，出租率，收款金额，租赁情况(缩租、
                      退租、扩租、新进户)等数据。
                    </p>
                  </li>
                </ul>
                <h5 class="f-title">物业</h5>
                <ul>
                  <li>
                    <p class="describe">
                      1. 需选择对应的正确模板填写复盘，由部门主管监督检查。
                    </p>
                  </li>
                </ul>
                <h5 class="f-title">办公室</h5>
                <ul>
                  <li>
                    <p class="describe">
                      1. 走访企业，需使用走访企业模板，由部门主管监督检查。
                    </p>
                  </li>
                  <li>
                    <p class="describe">
                      2.
                      会展活动工作复盘，需使用会展活动模板，将当日举办的活动名称、收费金额等信息
                      记录在内，由部门主管监督检查。
                    </p>
                  </li>
                  <li>
                    <p class="describe">
                      3. 人员发生调动时，需及时将信息反馈给数字技术部。
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                  <ul>
                    <li v-for="(activity, index) in lists" :key="index" >
                      <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp">
                      <a :href="activity.target" @click="tolink(index)" :class="link==index?'islink':''">{{activity.name}}</a>
                    </el-timeline-item>
                    </li>
                    <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                  </ul>
                  
                </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage:0,
      lists: [
        {
          name: "复盘须知",
          target: "#fpxz",
        },
        {
          name: "适用范围",
          target: "#syfw",
        },
        {
          name: "复盘规范",
          target: "#fpgf",
        },
        {
          name: "注意事项",
          target: "#zysx",
        },
      ],
      link:0,
    };
  },
  mounted() {
    window.addEventListener('scroll',this.getscroll)
  },
  methods: {
    
    tolink(index){
      this.link = index
    },
    // 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (this.percentage <= 16) {
        this.tolink(0);
      } else if (this.percentage>16 && this.percentage<=44) {
        this.tolink(1);
      } else if(this.percentage>44 && this.percentage<=82){
        this.tolink(2);
      }else{
        this.tolink(3);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 90%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink{
  color: #4fc08d;
  font-weight: 700;
}
.describe::first-letter{
  font-size: 20px;
  font-weight: 700;
  text-shadow:3px 2px 2px #4fc08d;
}
</style>